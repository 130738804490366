#admin-app .modal-confirm-update-locations {
    width: 750px!important;
    
    .ant-modal-title {
        font-size: 18px!important;
        .icon-warning {
            margin-bottom: 20px!important;
        }
        > div {
            margin-bottom: 5px;
        }
        > h5 {
            font-size: 13px;
            font-weight: bold;
        }
    }
    button.left {
        float: left;
    }
    .ant-tree {
        .ant-tree-switcher {
            display: none;
        }
    }
    .info {
        color: var(--info-color);
    }
    .ant-input {
        line-height: unset;
    }
}