#user-app {
    .MuiList-root {
        padding: 0;
    }
    li.c-list-item {
        min-height: 44px;
        color: var(--primary-color);
        line-height: 20px;
        padding-left: 0;
        margin-bottom: 6px;
        cursor: pointer;
        &.c-list-item-arrow {
            &:after{
                content: '';
                position: absolute;
                right: 4px;
                transform: rotate(45deg);
                width: 9px;
                height: 9px;
                border: 2px solid var(--primary-color);
                margin-right: 5px;
                border-left: none;
                border-bottom: none;
            }
        }
        &.loading, &.c-list-item-arrow.loading {
            &:after{
                display: block;
                width: 18px;
                height: 18px;
                content: '';
                border-radius: 50%;
    
                border-top: 2px solid transparent;
                border-left: 2px solid var(--primary-color);
                border-right: 2px solid var(--primary-color);
                border-bottom: 2px solid var(--primary-color);
                animation: spinner-anime 1s linear infinite;
            }
        }
        .MuiListItemIcon-root {
            min-width: 39px;
            img {
                width: 22px;
                height: 22px;
            }
        }
        .MuiListItemSecondaryAction-root {
            right: 0;
        }
    }
}
