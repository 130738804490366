#admin-app {
    .tree-input-search {
        margin-bottom: 15px;
    }
    .ant-form-item-has-error {
        .tree-input-search {
            input {
                border-color: transparent!important;
            }
        } 
    }
    .ant-tree {
        background: transparent;
        .ant-tree-treenode {
            .ant-tree-node-content-wrapper {
                font-size: 12px;
                font-weight: bold;
                padding: 0 8px;
                background-color: transparent;
                width: 100%;
                .ant-tree-title {
                    color: var(--primary-color);
                }
            }
        }
        .tree-search-value {
            background: #ffeaaa;
        }
    }
    .ant-tree.multiple {
        .ant-tree-checkbox-inner {
            border-radius: 0;
            border-color: var(--primary-color);
        }
        .ant-tree-treenode {
            padding: 0;
            width: 100%;
            &.ant-tree-treenode-checkbox-checked {
                background: var(--selected-color);
            }
            &:hover {
                background: var(--app-background);
            }
            >.ant-tree-indent {
                > .ant-tree-indent-unit:first-of-type {
                    display: none;
                }
            }
            &._all >.ant-tree-switcher {
                > span {
                    display: none; 
                }
            }
            >.ant-tree-switcher {
                margin-top: 6px;
            }
            >.ant-tree-checkbox {
                margin-top: 11px;
               
            }
            >.ant-tree-node-content-wrapper {
                padding: 7px;
                border-radius: 0;
            }
            &._all {
                border-bottom: 1px var(--border-color) solid;
            }
            
        }
    }
    .ant-tree:not(.multiple) {
        .ant-tree-treenode {
            width: 100%;
            &:hover {
                background-color: var(--app-background);
            }
            &.ant-tree-treenode-selected {
                background: var(--selected-color);
            }
        }
    }
}