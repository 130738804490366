#user-app {
    .c-carousel {
        display: block;
        .slider-control-centerright,
        .slider-control-centerleft {
            display: none;
        }
        /* .slide {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        } */
        .slider-control-bottomcenter {
            position: relative!important;
            top: 25px;
        }
        ul {
            margin-bottom: 15px!important;
            li {
                height: 15px;
                margin-bottom: 0;
            }
        }
        .slider-list {
            display: flex;
        }
    }
}