.quick{
    > .content {
        padding-bottom: 140px !important;
    }
    .footer{
        height: 140px !important;
        > .container {
            padding-top: 15px;
            display: block;
            .c-btn.secondary {
                background: unset;
                margin-top: 10px;
                border: 2px var(--primary-color) solid;
                border-radius: var(--button-border-radius);
                color: var(--primary-color);
            }
        }
    }
    .image-circle {
        display: none;
    }
} 
