#user-app {
    .c-tabs  {
        --tabs-height: 38px;
        min-height: calc(var(--tabs-height) + 1);
        position: fixed;
        z-index: 1000;
        width: calc(100% - 35px)!important;
        max-width: 665px;
        background: white;
        .MuiButtonBase-root {
            font-family: var(--font-family);
            color: var(--primary-color);
            text-transform: none;
            font-size: 15px;
            min-height: var(--tabs-height);
            padding: 0;
        }
        .Mui-selected {
            font-weight: bold;
            color: var(--highlight-color);
        }
        .MuiTabs-flexContainer {
            border-bottom: 1px var(--border-color) solid;
        }
        .MuiTabs-indicator {
            height: 3px;
            background-color: var(--highlight-color);
        }
    }
    .c-tab-panel {
        padding: 24px 0;
        padding-top: 60px;
        min-height: 80vh;
    }
}