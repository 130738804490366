#admin-app {
    .ant-upload {
        border-color: var(--border-color);
        &.ant-upload-disabled {
            opacity: .5;
        }
        &:hover {
            border-color: #a5abb9;
        }
        .ant-upload-drag-icon {
            margin-bottom: 10px;
            .anticon {
                font-size: 34px;
            }
        }
        .ant-upload-text {
            font-size: 14px;
            font-weight: bold;
            color: var(--primary-color);
        }
        .ant-upload-hint {
            font-size: 12px;
        }
    }
    .ant-upload-select {
        width: 100%;
        .ant-upload-drag {
                
            p.ant-upload-text {
                font-size: 15px;
            }
            p.ant-upload-hint {
                font-size: 13px;
            }
        }
    }
    .ant-upload.ant-upload-drag {
        background: transparent;
        border: 1px dashed var(--input-border-color);
    }
    .ant-upload-list-item-card-actions  {
        opacity: 1;
        .anticon-delete {
            color: var(--error-color);
        }
    }
}
