#admin-app {
    .location-multiselect-dropdown {
        border-radius: 0;
        .ant-tree-indent {
            > .ant-tree-indent-unit:first-of-type {
                display: block!important; 
            }
        }
        .ant-tree-treenode {
            padding: 0;
            width: 100%;
        }
        
        .dropdown-footer {
            border-top: 1px var(--border-color) solid;
            display: grid;
            grid-template-columns: 1fr auto auto;
            align-items: center;
            column-gap: 12px;
            padding: 12px;
        }

        .ant-select-tree-title {
            font-size: 13px;
        }
        .ant-select-empty, .empty {
            font-size: 13px;
            text-align: center;
            padding: 40px 0;
            color: rgba(0, 0, 0, 0.25);
        }
        
        .ant-select-tree-node-selected {
            background: var(--selected-color);
        }
        .ant-select-item {
            code {
                font-size: 11px;
                color: #CBCED5;
            }
        }
    }
}