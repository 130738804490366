#user-app {
    .c-upload {
        border-radius: var(--input-border-radius);
        background-color: var(--input-background);
        border: var(--input-border)!important;
        height: var(--input-height);
        &[disabled] {
            color: rgba(0, 0, 0, 0.4);
        }
    }
    
    .c-upload-dragger{
        border-radius: var(--input-border-radius);
        background-color: var(--input-background)!important;
        text-align: center;
        padding: 24px 12px;
        border: 1px dashed var(--input-background);
        &:hover {
            border-color: var(--primary-color);
        }
        .ant-upload-text {
            font-weight: bold;
            color: var(--muted-color);
            font-size: 17px;
        }
        .ant-upload-hint {
            color: var(--muted-color);
            font-size: 12px;
        }
        .upload-drag-icon svg{
            font-size: 85px;
            color: #d3d3d3!important;
        }
    }
    .ant-upload-disabled {
        opacity: .5;
    }
}


.ant-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #2e3a59;
    font-size: 15px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    line-height: 1.5715;
    > .ant-upload-list-text-container {
        transition: opacity .3s,height .3s;
        .ant-upload-list-item {
            position: relative;
            height: 23.5725px;
            margin-top: 8px;
            font-size: 14px;
            .ant-upload-list-item-info {
                height: 100%;
                padding: 0;
                .ant-upload-span {
                    display: flex;
                    align-items: center;
                    >* {
                        flex: none;
                    }
                    .ant-upload-list-item-name {
                        flex: auto;
                        padding: 0 8px;
                    }
                    .ant-upload-list-item-card-actions-btn {
                        border: none;
                        background: none;
                        .anticon-delete {
                            color: var(--error-color);
                        }
                    }
                }
            }
        }
    }
}

/*
.ant-form-item {
    .ant-upload-select {
        width: 100%;
        .ant-upload-drag {
            p.ant-upload-text {
                font-size: 15px;
            }
            p.ant-upload-hint {
                font-size: 13px;
            }
        }
    }
    .ant-upload.ant-upload-drag {
        background: white;
    }
    .ant-upload-list-item-card-actions  {
        opacity: 1;
        .anticon-delete {
            color: var(--error-color);
        }
    }
}*/
