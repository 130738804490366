.c-card {
    background: #F9F9F9;
    border-radius: 15px;
    margin: 20px 0;
    padding: 15px 17px;
    span {
        display: grid;
        grid-template-columns: 36px 1fr;
        column-gap: 17px;
        .title {
            border-bottom: 1px solid #EAEBEE;
            font-size: 17px;
            display: grid;
            align-items: center;
        }
    }
    .c-card-content {
        padding-left: 53px;
        padding-top: 9px;
        font-size: 13px;
        p {
            margin-bottom: 0;
        }
        strong {
            float: right;
        }
    }
}
