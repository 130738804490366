.no-access {
    .text {
        padding: 20px 50px 0 50px;
        h2 {
            text-align: center;
        }
        h3 {
            text-align: center;
            line-height: 20px;
            margin: 20px auto;
        }
    }   
}

.no-access-icon {
    position: relative;
    width: 100px;
    height: 111px;
    margin: 0 auto;
    .front, .back, .lines {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .back {
      transform: rotate(14deg) translate(80px, 22px);
      animation: back 350ms forwards;
      opacity: 0;
      animation-delay: 1000ms;
    }
    .front .square-1,
    .front .square-2,
    .front .square-3 {
      opacity: 0;
    }
    .front .square-1 {
      opacity: 0;
      animation: fade 500ms forwards;
      animation-delay: 500ms;
    }
    .front .square-2 {
      opacity: 0;
      animation: fade 500ms forwards;
      animation-delay: 600ms;
    }
    .front .square-3 {
      opacity: 0;
      animation: fade 500ms forwards;
      animation-delay: 700ms;
    }
    .lines .paper {
      transform: translate(133px) scale(-1, 1);
      stroke-dasharray: 265;
      stroke-dashoffset: 265;
      animation: draw 500ms forwards;
    }
    .lines .line-1 {
      stroke-dasharray: 20;
      stroke-dashoffset: 20;
      animation: draw 250ms forwards;
      animation-delay: 750ms;
    }
    .lines .line-2 {
      stroke-dasharray: 35;
      stroke-dashoffset: 35;
      animation: draw 250ms forwards;
      animation-delay: 800ms;
    }
    .lines .line-3 {
      stroke-dasharray: 27;
      stroke-dashoffset: 27;
      animation: draw 250ms forwards;
      animation-delay: 850ms;
    }
    .lines .line-4 {
      stroke-dasharray: 32;
      stroke-dashoffset: 32;
      animation: draw 250ms forwards;
      animation-delay: 900ms;
    }
    .lines .line-5 {
      stroke-dasharray: 27;
      stroke-dashoffset: 27;
      animation: draw 250ms forwards;
      animation-delay: 950ms;
    }
    .lines .line-6 {
      stroke-dasharray: 32;
      stroke-dashoffset: 32;
      animation: draw 250ms forwards;
      animation-delay: 1000ms;
    }
  
  
  @keyframes draw {
    100% { stroke-dashoffset: 0; }
  }
  @keyframes back {
    0% { opacity: 0; transform: rotate(14deg) translate(80px, 22px); }
    50% { opacity: 0; }
    100% { opacity: 1; transform: none; }
  }
  @keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}
