#user-app .pwa-install{
    box-shadow: -22px 0px 0px 0px var(--primary-color), 22px 0px 0px 0px var(--primary-color);
    background: var(--primary-color);
    display: grid;
    grid-template-columns: 1fr auto auto;
    padding: 10px 0;
    line-height: 18px;
    margin-top: 10px;
    .c-btn {
        width: 92px;
        height: 30px;
        grid-column: 3;
        border: none;
        font-size: 11px!important;
        margin-bottom: 10px;
        > span {
            display: block;
            line-height: 30px;
            font-size: 12px;
        }
        &.primary {
            background: white;
            color: var(--primary-color);
        }
        &.not-now {
            grid-column: 2;
            background: transparent;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    p {
        color: white !important;
        font-weight: bold;
        font-size: 12px;
        width: 100%;
        padding-left: 52px;
        grid-column-end: 4;
        grid-column-start: 1;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: white url(../../../../brands/lintelio/images/logo.svg) no-repeat 7.5px 4.5px;
            background-size: auto 29px;
            top: 0;
            left: 0;
        }
    }
}