#user-app {
    .c-date-dialog {
        * {
            font-family: var(--font-family);
        }
        .MuiGrid-root h4 {
            font-weight: bold;
        }
        .Mui-selected {
            font-weight: bold;
            background: var(--highlight-color);
        }
        .MuiButton-textPrimary {
            color:  var(--highlight-color);
            &:first-of-type {
                color: var(--primary-color);
            }
        }
        .MuiButton-root {
            text-transform: none;
            font-weight: bold;
        }
    }
}