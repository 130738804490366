#user-app .no-data-found {
    .no-data-found-img {
        width: 65vw;
        max-width: var(--app-width);
        margin: 8vh auto;
        display: grid;
    }
    h1 {
        text-align: center;
    }
}