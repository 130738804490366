.slide-container {
    padding-top: 10px;
    min-height: 200px;
    overflow: hidden;
    display: grid;

    .m-item {
        width: 100%;
        min-width: 200px;
        max-width: 330px;
        transition: all 250ms ease;
        > div {
            width: 100%;
            position: relative;
            min-height: 35px;
            line-height: 50px;
        }
    }
    &.first {
        .m-item-second ul, 
        .m-item-third ul {
            display: none;
        }
    }
    &.second {
        .m-item {
            transform: translate(-100%);
        }
        .m-item-first ul, 
        .m-item-third ul {
            display: none;
        }
    }
    &.third {
        .m-item {
            transform: translate(-200%);
        }
        .m-item-first ul, 
        .m-item-second ul {
            display: none;
        }
    }
}