#user-app .c-textarea {
    height: auto;
    min-height: var(--input-height);
    padding: 13px 0 13px 11px;
    color: var(--primary-color)!important;
    font-weight: bold;
    font-size: var(--input-font-size)!important;
    font-family: var(--font-family);
    > textarea {
        padding-right: 11px;
    }
}
.c-textarea-count {
    position: relative;
    top: -20px;
    left: -10px;
    text-align: right;
    color: #dbdbdb;
    font-size: 11px;
    font-weight: bold;
}