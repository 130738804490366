#admin-app {
        
    .select-tags-dropdown{
        display: none;
        
    }
    .select-tags {
        .ant-select-selector {
            padding: 0 9px;
            background: var(--input-background) !important;
            border: 1px solid var(--input-border-color);
            .ant-select-selection-overflow {
                padding: 4px 0 6px 0;
            }
            .ant-select-selection-item {
                background: var(--primary-color);
                color: white;
                height: 22px;
                font-size: 12px;
                line-height: 22px;
                font-weight: bold;
                padding: 0 23px 0 7px;
                margin: 3px 3px 0 0;
                > .ant-select-selection-item-content {
                    font-size: 12px;
                    margin: 0;
                }
                > .ant-select-selection-item-remove {
                    position: absolute;
                    background: white;
                    border-radius: 100%;
                    height: 10px;
                    width: 10px;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 5px;
                    > .anticon.anticon-close{
                        position: absolute;
                        color: var(--primary-color);
                        margin: auto;
                        font-size: 6px;
                        top: 2px;
                        right: 2px;
                    }
                }
            }
            .ant-select-selection-search {
                margin: 0;
            }
            input {
                margin-top: -5px;
                min-width: 50px;
                margin-left: 5px;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }
}