.content-403 {
    margin-top: 5vh;
    .ant-result-icon {
        background-image: var(--access-denied);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        height: 35vh;
        > span {
            display: none;
        }
    }
    .ant-result-title {
        font-weight: bold;
        font-size: 25px;
        color: var(--primary-color);
        margin: 5px auto 25px auto;
    }
    .ant-result-subtitle {
        font-size: 14px;
        color: var(--primary-color);
    }
}