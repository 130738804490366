.modal-import-info{
    width: 660px !important;
    .ant-modal-body{
        padding: 5px 30px 25px 30px !important;
        font-size: 13px;
        margin-top: 21px;
        .ant-divider.ant-divider-horizontal:first-child{
            margin-top: 0;
            margin-bottom: 15px;
        }
        .ant-divider.ant-divider-horizontal:last-child{
            margin-bottom: 0;
            margin-top: 15px;
        }
        p:not(.mb){
            margin-bottom: 0;
        }
        .body{
            margin-left: 60px;
            margin-right: 30px;
        }
        .p-bulk p{
            font-size:12px;
        }
    }
    .ant-modal-footer{
        padding: 20px 30px 30px 30px;
        border: none;
        a.ant-btn{
            position: relative;
            display: inline-flex;
            text-decoration: none;
            margin-right: 10px;
            span{
                margin: auto;
            }
        }
        .ant-btn{
            height: 30px;
            span{
                font-weight: bold;
                font-size: 13px;
                line-height: 100.9%;
            }
        }
    }
    .ant-modal-header {
        border-bottom: none !important;
        padding: 0 35px 25px 25px !important;
        .import-info-header{
            display: flex;
            margin-bottom: 25px;
            >.ant-btn-link{
                padding: 0;
                align-self: flex-end;
                >span{
                    font-weight: bold;
                    font-size: 13px;
                    color: var(--info-color);
                    margin-left: 5px;
                    svg path {
                        stroke: currentColor;
                        stroke-width: 120px;
                    }
                }
                .anticon.anticon-down, .anticon.anticon-up{
                    font-size: 8px;
                    transform: translateY(-1px);
                }
            }
            >div{
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
                text-align: left;
                margin-left: 20px;
                >h1{
                    font-weight: 900;
                }
                >span{
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
            > img{
                width:40px;
                height: 40px;
            }
        }
    }
}
