#user-app {
    .lintelio-wrapper .MuiSvgIcon-root,
    .MuiModal-root .MuiSvgIcon-root {
        color: var(--primary-color);
        &.Mui-disabled {
            color: var(--disabled-color);
        }
    }
    .Mui-disabled {
        color: var(--disabled-color);
    }
}

