.lintelio-wrapper {
    .content {
        position: relative;
        top: var(--header-height);
        padding: var(--content-padding);
        width: 100%;
        max-width: 701px;
        margin: 0 auto;
        padding-bottom: 99px;
        background: var(--app-background);
        .container {
            width: 100%;
            margin: 0 auto;
            padding: 0;
        }
    }
}

