#admin-app .module-info {
    background-position-x: calc(100% + 95px);
    background-position-y: -95px;
    background-size: 615px;
    background-repeat: no-repeat;
    .ant-result {
        margin-top: 75px;
        .ant-result-title {
            color: var(--primary-color);
        }
    }
    
    img:first-of-type {
        float: left;
        margin: 25px; 
        margin-top: 0;
    }
}
