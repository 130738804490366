@margin: calc(-1 * var(--app-content-padding));
.c-banner {
    border-radius: 0;
    padding: 15px 19px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    top: -24px;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    &.highlight {
        background: #fe3b3b;
        color: white;
        a {
            color: white;
        }
    }
    &.blue {
        background: var(--no-status);
        color: white;
        a {
            color: white;
        }
    }
    &.white {
        color: var(--primary-color);
    }
    &.yellow {
        background: var(--status-required);
    }
    &.c-banner-closable {
        padding-right: 30px;
    }
    .close {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        border-radius: 50%;
        background-color: black;
        width: 18px;
        height: 18px;
        opacity: .5;
    }
}

