#user-app .profile-form {
    .ant-form-item-control-input {
        .am-list-content {
            a {
                text-decoration: underline;
            }
        }
        .am-list-thumb {
            margin-right: 3px!important;
        }
    }
}