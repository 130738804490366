.c-spin {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    span {
        width: 50px;
        height: 50px;
    }
    svg {
        color: var(--color-info);
    }

    &.center {
        height: calc(100vh - var(--header-height) - var(--footer-height));
    }
}



@keyframes spinner-anime {
    100% {
        transform: rotate(360deg);
    }
}