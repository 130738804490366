.ant-tooltip {
    .ant-tooltip-arrow{
        border-color: black;
    }
    .ant-tooltip-inner {
        font-size: 12px;
        border-radius: 3px;
        background: #404040;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        padding: 5px 15px;
    }
    a {
        color: white !important;
        text-decoration: underline;
    }
}