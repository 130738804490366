#user-app {
    .ant-form-item {
        margin-bottom: 21px;
        box-sizing: border-box;
        padding: 0;
        color: var(--primary-color);
        font-size: 15px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum","tnum";
        margin: 0 0 24px;
        vertical-align: top;
        flex-direction: column;
        margin-bottom: 21px;
    }
    /*.ant-row {
        display: flex;
    }*/
    
    .ant-col {
        position: relative;
        min-height: 1px;
    }
    .ant-form-item-label {
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        padding: 0;
        line-height: 15px;
        width: 100%;
        > label {
            color: var(--primary-color);
            width: 100%;
            position: relative;
            width: 100%;
            height: auto;
            position: relative;
            display: inline-flex;
            align-items: center;
            max-width: 100%;
            font-size: 13px;
            &.ant-form-item-required{
                &::before {
                    display: none;
                }
                &:after {
                    content: '*';
                    color: var(--error-color);
                    display: inline-block;
                    font-weight: bold;
                    margin: 0 0 0 4px;
                }
            }
            .c-help {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                > .anticon {
                    margin: -3px;
                }
            }
        }
    }
    .ant-form-item-explain {
        font-size: 12px;
        .ant-form-item-explain-error {
            font-weight: bold;
            color: var(--error-color);
        }
    }
        
    .ant-form.ant-form-hide-required-mark {
        .ant-form-item-required:after {
            display: none!important;
        }
    }
    
}

@media (max-width: 575px) {
    .ant-form .ant-form-item {
        flex-wrap: wrap;
    }
}
