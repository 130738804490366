#user-app .profile-form .address {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 11px;
    > .ant-form-item {
        display: block;
    }
    > .ant-form-item:nth-child(1),
    > .ant-form-item:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 4;
    }
}   