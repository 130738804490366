#admin-app {
    --modal-header-height: 36px;
    --modal-footer-height: 50px;
    --modal-padding: 35px;
    .ant-modal {
        color: var(--primary-color);
        .ant-modal-content {
            background-color: transparent;
            border-radius: var(--modal-border-radius);
            padding: 21px 0;
            height: 100%;
            .ant-modal-header {
                background-color: transparent;
                padding: 0 var(--modal-padding);
                position: relative;
                border-bottom: 1px solid var(--border-color);
                .ant-modal-title {
                    text-align: center;
                    font-weight: bold;
                    color: var(--primary-color);
                    font-size: 14px;
                    line-height: 23px;
                    min-height: var(--modal-header-height);
                    .btn-add-right { 
                        position: absolute;
                        right: var(--modal-padding);
                        top: 0;
                    }
                }
            }
            .ant-modal-body {
                max-height: calc(100vh - 351px);
                overflow-y: auto;
                padding: 15px var(--modal-padding);
            }

            .ant-modal-footer {
                border-top: 1px solid var(--border-color);
                padding: 19px var(--modal-padding) 0 var(--modal-padding);
                margin: 0px;
            }
        }
        &.modal-with-grid {
            width: calc(100% - 70px) !important;
            max-width: 701px;
            height: calc(100% - 200px);
            padding-bottom: 0;
            .ant-modal-body {
                height: calc(100% - var(--modal-header-height) - var(--modal-footer-height));
                max-height: unset;
                .modal-with-grid-container fieldset {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 25px;
                    .modal-with-grid-double {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                }
            }
        }
        &.modal-with-icon  {
            .ant-modal-content {
                .ant-modal-header {
                    padding-bottom: 13px;
                    .ant-modal-title {
                        text-align: left;
                        img.icon-warning {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .ant-modal-body {
                padding-left: 105px;
            }
        }
    }
    .ant-modal:not(.white) {
        .ant-modal-content {
            background: var(--app-background);
        }
    }

    // clean up for tabs in modal
    .ant-modal {
        .ant-tabs.ant-tabs-top > .ant-tabs-nav {
            margin-left: 0;
        }
        .page-scroll  {
            height: auto!important;
            border-top: none;
            margin-left: 0;
        }
        .modal-page-footer {
            position: absolute;
            bottom: 21px;
            right: 21px;
            align-items: grid-end;
            button {
                margin-left: 10px;
            }
        }
    }
}