.card {
    border-radius: var(--input-border-radius);
    border: 1px solid var(--border-color);
    margin: 15px 0;
    padding: 15px 15px 0 15px;
    > h2 {
        border-bottom: 1px solid #EAEBEE;
        font-size: 17px;
        display: grid;
        align-items: center;
    }
}
