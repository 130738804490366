#admin-app .clear-filters {
    position: relative;
    .anticon:before {
        position: absolute;
        display: block;
        content: '';
        font-size: 12px;
        margin-left: 11px;
        margin-top: 5px;
        font-weight: bold;
    }
    
    &:not(:disabled) {
        .anticon {
            color: var(--info-color);
        }
        .anticon:before {
            content: 'x';
            color: var(--error-color);
        }
    }
}