#user-app .c-input {
    position: relative;
    height: var(--input-height);
    background-color: var(--input-background);
    border-radius: var(--input-border-radius);
    padding: 0 13px;
    input {
        color: var(--primary-color);
        font-weight: bold;
        font-family: var(--font-family);
        font-size: var(--input-font-size);
    }
    &.Mui-disabled {
        input {
            color: var(--disabled-color);
            -webkit-text-fill-color: unset;
        }
        .MuiSvgIcon-root {
            color: var(--disabled-color);
        }
    }
}

