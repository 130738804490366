.status-icon {
    --animation-delay: 250ms;
    position: relative;
    .lines, .mark {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .mark {
        animation: bounce 250ms forwards;
        animation-delay: 400ms + var(--animation-delay);
        opacity: 0;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .circle-big {
        stroke-dasharray: 150;
        stroke-dashoffset: 150;
        animation: draw 250ms forwards;
        animation-delay: var(--animation-delay);
    }
    .circle-small {
        stroke-dasharray: 120;
        stroke-dashoffset: 120;
        animation: inner 250ms forwards;
        animation-delay: 150ms + var(--animation-delay);
    }
    .circle-dot {
        opacity: 0;
        animation: fadeIn 500ms forwards;
        animation-delay: var(--animation-delay);
    }
    .warning-big {
        stroke-dasharray: 150;
        stroke-dashoffset: 150;
        animation: draw 250ms forwards;
        animation-delay: var(--animation-delay);
        transform: translate(.5px, 2px);
    }
    .warning-small {
        stroke-dasharray: 130;
        stroke-dashoffset: 130;
        animation: inner 250ms forwards;
        animation-delay: 150ms + var(--animation-delay);
        transform: translate(-6px, 3.5px);
    }
    
    &.ok {
        .mark {
            background-image: url(../../assets/images/ok.svg);
            background-size: 52%;
        }
    }
    &.hold {
        .mark {
            background-image: url(../../assets/images/no.svg);
            background-size: 43%;
        }
    }
    &.required,
    &.okpending {
        .mark {
            background-image: url(../../assets/images/alert.svg);
            background-size: 12%;
            margin-top: 1px;
        }
    }
}


#gradient-ok {
    stop:nth-child(1) {
        stop-color: #318F6F;
    }
    stop:nth-child(2) {
        stop-color: #80D3B7;
    }
}
#gradient-hold {
    stop:nth-child(1) {
        stop-color: #FE3B3B;
    }
    stop:nth-child(2) {
        stop-color: #FE3BBC;
    }
}
#gradient-required,
#gradient-okpending {
    stop:nth-child(1) {
        stop-color: #FE3BBC;
    }
    stop:nth-child(2) {
        stop-color: #FE3B3B;
    }
}

@keyframes inner {
    20% { stroke-dashoffset: 120; }
    100% { stroke-dashoffset: 10; }
}
@keyframes draw {
    100% { stroke-dashoffset: 0; }
}
@keyframes bounce {
    0% { transform: scale(0.1); opacity: 0; }
    60% { transform: scale(1.2); opacity: 0.6; }
    80% { transform: scale(0.95); opacity: .8; }
    100% { transform: scale(1); opacity: 1; }
}