
.phone-input-dropdown {
    ul {
        min-width: 250px;
        > li {
            height: 50px;
        }
        .MuiListItemIcon-root img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }
        .country-label{
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            margin-right: 6px;
        }
        .country-code{
            margin-left: 12px;
            color: #6b6b6b!important;
        }
    }
}