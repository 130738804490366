#user-app {
    .c-checkbox {
        margin-right: 0;
        margin-bottom: 10px;
        &.Mui-disabled {
            opacity: .4;
        }
        .MuiTypography-root{
            font-family: var(--font-family);
            font-size: 14px;
            line-height: 17px;
            font-weight: bold;
            color: var(--primary-color);
            a {
                text-decoration: underline;
            }
        }
        &.c-checkbox-loading {
            .MuiCheckbox-root {
                animation: spinner-anime 1s linear infinite;
                opacity: .5;
            }
        }
    }
}