
.c-avatar {
    --defaultSize: 40px;
    border-radius: 50%;
    width: var(--defaultSize);
    height: var(--defaultSize);
    line-height: var(--defaultSize);
    text-align: center;
    color: white;
    background: var(--no-status);
    &.white {
        background: white;
        color: var(--primary-color);
    }
}