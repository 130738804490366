#admin-app {
    .ant-radio-group {
        label.ant-radio-wrapper {
            min-height: auto;
            > .ant-radio {
                &.ant-radio-checked {
                    .ant-radio-inner {
                        border-color: var(--primary-color);
                    }
                }
                .ant-radio-inner {
                    border-color: var(--input-border-color);
                }
                > span{
                    font-size: 13px;
                }
            }
            
        }
        /* old styles
        .ant-radio {
            .ant-radio-inner {
                border: 1px var(--primary-color) solid;
                background: var(--app-background);
                &:after {
                    background-color: var(--primary-color);
                }
            }
        }
        .ant-radio-disabled {
            .ant-radio-inner {
                opacity: .25;
            }
        }
        label > span{
            font-size: 13px;
        }*/
    }
}