#admin-app {
    .ant-form-item {
        margin-bottom: 12px;

        .ant-form-item-label {
            line-height: 22px;
            overflow: unset;
            display: grid;
            align-items: center;
            padding: 0px;

            >label {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: unset;
                padding-right: 15px;
                color: var(--primary-color);

                >span {
                    position: relative;
                }
            }

            &>label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {

                &::before,
                &::after {
                    display: none;
                }

                >span:first-of-type:after {
                    color: var(--error-color);
                    font-weight: bold;
                    content: '*';
                    margin-left: 3px;
                }
            }
        }

        .ant-form-item-control {
            .ant-form-item-extra {
                font-size: 12px;
            }
        }
    }

    .ant-form-item-explain-error {
        font-size: 12px;
        font-weight: bold;
    }

    .ant-tooltip .ant-tooltip-inner {
        font-size: 12.5px;
        padding: 5px 15px;
        white-space: pre-line;
    }
}