


.lintelio-wrapper {
    &:not(&.wrapper-auth) &:not(&.autoscreening-questions) {
        .image-circle {
            max-width: var(--app-width);
            width: 100vw !important;
            height: 50vh !important;
            margin: 0 auto;
        }
    }
}

.image-circle {
    display: block;
    margin: 35px auto 45px auto;
    &:not(.no-bgr) {
        width: 200px;
        height: 200px;
        background-color: white;
        border-radius: 100%;
    } 
    &.no-bgr {
        padding: calc((150px - 84px)/2);
        width: 150px;
        height: 150px;
    }   
    > div {
        width: 100%;
        height: 100%;    
        background-size: 84px;
        background-position: center;
        background-repeat: no-repeat;
    }
    &.help > div {
        background-image: url(../../assets/images/ico.help.svg);
    }
    &.reminder > div {
        background-image: url(../../assets/images/icon.reminder.svg); 
        background-position-y: 55px;
    }
    &.auth-phone > div {
        background-image: url(../../assets/images/auth.lint.phone.svg);
    }
    &.auth-email > div {
        background-image: url(../../assets/images/auth.lint.email.svg);
    }
    &.auth-password > div {
        background-image: url(../../assets/images/auth.lint.password.svg);
    }
    &.sent-success > div {
        background-image: url(../../assets/images/report-test-success-result.svg);
        background-size: 80% auto;
    }
    &.report-testing  > div {
        background-image: url(../../assets/images/report-test-success-result.svg);
        background-size: 85% auto;
    }
    &.report-vaccination  > div,
    &.report-immunization > div,
    &.report-exempt  > div,
    &.report-booster  > div
    {
        background-image: url(../../assets/images/report-vaccine-success-result.svg);
        background-size: auto 80%;
    }
    &.success  > div { 
        background-image: url(../../assets/images/pass.svg);
        background-size: 85% auto;
    }
}

@media only screen and (max-width: 376px) {
    .image-circle {
        &:not(.no-bgr) {
            width: 24.6vh;
            height: 24.6vh;
        }
        > div {
            width: 100%;
            height: 100%;    
            background-size: 10.5vh;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.auth-email > div {
            background-position-y: 55%;
        }
        &.auth-password > div {
            background-position-y: 47%;
        }
    }
}