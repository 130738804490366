#admin-app {
    .ant-picker {
        width: 100%;
        background: var(--input-background);
        height: var(--input-height);
        border-radius: var(--input-border-radius);
        border-color: var(--input-border-color);
        .ant-picker-input input {
            font-weight: bold;
            font-size: 13px;
            &:not([disabled]) {
                color: var(--primary-color);
            }
        }
        .ant-picker-clear {
            background: var(--input-background);
        }
    }
    .ant-form-item-has-error .ant-picker {
        border-color: var(--error-color);
    }
    .ant-picker-dropdown {
        font-size: 12px;
        .ant-picker-header {
            button {
                font-weight: bold;
            }
        }
        th {
            font-weight: bold;
        }
        th,
        td {
            color: var(--primary-color);

            &.ant-picker-cell-selected>.ant-picker-cell-inner {
                background-color: var(--primary-color);
            }
        }
        >.ant-picker-panel-container {
            background: var(--input-background);
        }
    }
}