.lintelio-wrapper{
     .footer {
        position: fixed;
        height: var(--footer-height);
        background: white;
        border: none;
        width: 100%;
        bottom: 0;
        right:0;
        left: 0;
        z-index: 8;
        .container {
            display: grid;
            max-width: 701px;
            border-top: 1px solid #2E3A5910;
            width: var(--app-width);
            height: 100%;
            padding: 0;
            margin: 0 auto;
            text-align: center;
            > .c-btn {
                margin: auto auto;
            }
        }
     }
}