
#admin-app {
    .ant-table-filter-dropdown {
        padding: 12px;
        max-height: 60vh;
        .ant-table-filter-dropdown-btns {
            padding-top: 13px;
            .ant-btn {
                padding: 5px;
            }
        }
        .ant-input-search {
            display: block;
            margin-bottom: 13px;
        }
        .ant-input {
            background: white;
            border-color: var(--input-border-color);
        }
        .ant-input-search-button {
            background-color: white;
        }
        .ant-checkbox-group {
            max-height: 330px;
            overflow-x: hidden;
        }
        .empty  {
            display: block;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.25);
        }
    }
    .table-filter-dropdown-search {
        .ant-checkbox-group {
            width: 350px;
        }
        .ant-input-search {
            width: 350px;
        }
    }
}
