
.brand-logo {
    z-index: 2;
    max-height: 55px;
    max-width: 135px;
    vertical-align: bottom;
    &.left {
        position: absolute;
        max-width: 135px;
        top: 27px;
        left: 25px;
    }
    &.middle {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    &.small {
        max-height: 35px;
        max-width: 135px;
    }
}