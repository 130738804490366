.scroll-container {
    overflow-y: auto;
    &.has-arrow {
        padding-bottom: 80px!important;
    }
    &.is-scrolled {
        .arrow {
            transform: translate(-50%) rotate(180deg)!important;
        }
    }
    .scroll-arrow-container {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: linear-gradient(0deg, #FFFFFF 49.48%, rgba(255, 255, 255, 0) 92.27%);
        height: 77px;
        left: 0px;
        z-index: 10;
        border-radius: 0 0 10px 10px;
        .arrow {
            position: absolute;
            bottom: 13px;
            left: 50%;
            transform: translate(-50%);
            transition: 250ms;
        }
    }
}