
 #admin-app  .ant-input-search {
    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    padding-left: 3px;
    background: var(--input-background);
    input {
        background: var(--input-background);
        box-shadow: none;
        border-color: transparent!important;
    }
    button { 
        background: var(--input-background);
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 2px;
        box-shadow: none;
    }
}