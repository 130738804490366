.bottom-menu {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
    width: calc(100% + 2 * var(--app-padding));
    height: 100%;
    padding: 0;
    margin: 11px calc(-1 * var(--app-padding))!important;
    a {
        justify-self: center;
    }
}

.menu-bttn {
    --width: 41px;
    position: relative;
    width: var(--width);
    height: var(--width);
    border-radius: 50%;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 20px;
    text-align: center;
    &.loading{
        opacity: 0.25;
        cursor: wait;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--width);
        height: var(--width);
        background-repeat: no-repeat;
        background-position: center;
    }
    span {
        position: absolute;
        top: 45px;
        left: 50%;
        transform: translate(-50%);
    }
    &.active {
        background: var(--secondary-color);
        span {
            font-weight: bold;
        }
        &::after {
            filter: saturate(0) brightness(0) invert(1);
        }
    }
    &.inactive {
        &::after{
            opacity: .15;
            filter: saturate(0) brightness(0);
        }
        &.active {
            &::after {
                opacity: 1;
                filter: saturate(0) brightness(0) invert(1);
            }
        }
        span {
            opacity: .35;
        }
    }
    &.home::after {
        background-image: url(../../assets/icons/ico-home.svg);
    }
    
    &.pandemic::after, &.pandemic::after {
        background-image: url(../../../../assets/images/modules/ico-screen.svg);
    }
    &.reports::after {
        background-image: url(../../../../assets/images/modules/ico-reports.svg);
    }
    &.alerts::after, &.messages::after {
        background-image: url(../../../../assets/images/modules/ico-alerts.svg);
    }
    &.benefits::after {
        background-image: url(../../../../assets/images/modules/ico-benefits.svg);
    }
    &.attendance::after {
        background-image: url(../../../../assets/images/modules/ico-attendance.svg);
    }
    &.telehealth::after {
        background-image: url(../../../../assets/images/modules/ico-telehealth.svg);
    }
    &.incident::after {
        background-image: url(../../../../assets/images/modules/ico-assistance.svg);
        background-size: 27px;
    }
    &.integrityLine::after {
        background-image: url(../../../../assets/images/modules/ico-integrity.svg);
        background-size: 27px;
    }
    &.lifeworks::after {
        background-image: url(../../../../assets/images/modules/ico-lifeworks.svg);
        background-size: 27px;
    }
    &.immunization::after {
        background-image: url(../../../../assets/images/modules/ico-immunization.svg);
        background-size: 27px;
    }
    &.more::after {
        background-image: url(../../assets/icons/ico-more.svg);
    }
}
.menu-bttn + span {
    top: 13px;
    right: 15px;
    border: 1px white solid;
}