// Used to disable inputs when fieldset[disabled]
.input-disabled {
    cursor: not-allowed;
    color:var(--disabled-color)!important;
    > * {
        pointer-events: none;
        color: var(--disabled-color)!important;
    }
    p {
        color: var(--disabled-color)!important;
    }
}

.ant-form {
    font-size: var(--font-size);
    font-family: var(--font-family);
}
    
fieldset[disabled] {
    .ant-upload,
    .ant-input,
    .ant-input-number,
    .ant-switch,
    .ant-select,
    .ant-radio-group,
    .ant-picker,
    .ant-select,
    .ant-checkbox-wrapper,
    button.day,
    button.btn-delete
    {
        cursor: not-allowed;
        color:var(--disabled-color)!important;
        > * {
            pointer-events: none;
            color: var(--disabled-color)!important;
        }
    };
    .ant-upload,
    .ant-switch,
    .ant-radio-group,
    .ant-select,
    .ant-checkbox-wrapper,
    button.day
     {
        opacity: .6;
    }
    .ant-picker {
        input {
            color: var(--disabled-color)!important;
        }
        ~ div {
            display: none;
        }
    }
}