.lintelio-wrapper.no-underline { 
    .header > .container {
        border-bottom: 0px;
    }
}
.lintelio-wrapper .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    background: white;
    box-shadow: none;
    z-index: 2;
    color: var(--primary-color);
    z-index: 9;
    .c-btn {
        font-weight: bold;
        color: var(--primary-color);
        padding: 0 10px;
    }
    .c-btn-link[disabled] {
        opacity: .25;
    }
    .logo {
        max-height: 29px;
        max-width: 129px;
    }
    .container {
        max-width: 701px;
        border-bottom: 1px solid #EAEBEE;
        height: var(--header-height);
        grid-template-columns: 1fr 2fr 1fr;
        text-align: center;
        display: grid;
        align-content: center;
        width: var(--app-width);
        margin: 0 auto;
    
        >.left, >.right, >.middle {
            display: grid;
            align-content: center;
            position: relative;
        }
        >.left {
            text-align: left;
            >.MuiIconButton-root {
                width: 40px;
            }
            .bttn-burger {
                width: 44px;
                height: 44px;
                span {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    &::before, &::after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        border-radius: 2px;
                        background: var(--primary-color);
                        left: 0;
                    }
                    &::before {
                        top: 22px;
                        width: 12px;
                    }
                    &::after {
                        top: 17px;
                        width: 20px;
                    }
                }
            }
        }
        >.right {
            text-align: right;
            > .c-btn.link {
                text-align: right;
                padding-right: 0;
                display: block;
            }
            >.MuiIconButton-root {
                width: 40px;
                margin-left: auto;
            }
        }
        >.middle {
            font-size: 18px;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0 auto;
            line-height: 21px;

            .brand-logo {
                max-height: 35px;
            }
        }
    }
}