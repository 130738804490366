#admin-app {
    .table-filter-dropdown-numbers {
        .ant-input-number {
            width: 90px;
            margin-bottom: 8px;
            background-color: transparent;
            &:first-of-type {
                border-radius: 3px 0 0 3px;
                margin-right: 10px;
            }
            &:not(:first-of-type) {
                border-radius: 0 3px 3px 0;
                margin-left: 10px;
            }
            .ant-input-number-input {
                font-size: 13px;
                font-weight: 400;
                background: none!important;
            }
        }
    }
}
    