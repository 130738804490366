#user-app {
    .c-skeleton {
        background-color: #f9f9f9;
        border-radius: 15px;
        height: 150px;
        width: 100%;
        max-width: unset;
        text-align: center;
        padding-top: 60px;
        color: var(--muted-color);
    }
}