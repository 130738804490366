#user-app .quick-screen-message {
   text-align: center;
    font-weight: bold;
    padding: 25px 15px 15px 15px;
    > div {
        font-size: 15px;
    }
    .c-btn {
        height: 35px;
        border: 2px solid white!important;
        margin: 15px auto 0 auto;
        color: white;
        line-height: 35px;
        font-weight: bold;
        font-size: 13px;
    }
}