.table {
    .react-resizable-handle {
        position: absolute;
        width: 10px;
        height: 100%;
        bottom: 0;
        right: 0;
        cursor: col-resize;
        z-index: 1;
        border-right: 1px #f0f0f0 solid;
    } 
}

