body .c-btn{
    font-weight: bold;
    font-size: 15px!important;
    height: var(--button-height);
    text-transform: none;
    color:  var(--primary-color);
    /*background-color: transparent;
    overflow: unset;
    text-overflow: unset; 
    word-break: normal;
    */
    font-family: var(--font-family);
    &:before {
        display: none;
    }
    &.ghost{
        color:  var(--primary-color);
    }
    &.circle {
        border-radius: 50%;
        min-width: unset;
    }
    &.link{
        border: none;
        height: auto;
        padding: 0;
        color:  var(--primary-color);
        &.am-button-active {
            background-color: transparent!important; 
            border: none;      
        }
    }
    &.primary {
        background-color: var(--primary-color);
        &:not(.circle) {
            border-radius: var(--button-border-radius);
        }
        color: white;
    }
    &.default {
        &:not(.circle) {
            border-radius: var(--button-border-radius);
        }
        border: 2px solid var(--primary-color)!important;
        color:  var(--primary-color);
    }
    &.block {
        width: 100%;
    }
    &.MuiButton-sizeSmall {
        height: 35px;
        line-height: 30px;
    }
    &.Mui-disabled {
        opacity: .3;
        cursor: not-allowed;
    }
}


#user-app {
    .MuiIconButton-root.Mui-disabled {
        opacity: .3;
        cursor: not-allowed;
    }
}
