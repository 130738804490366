.lintelio-menu-drawer {
    .MuiPaper-root {
        width: 330px;
        display: grid;
        grid-template-rows: 81px 1fr 45px;
    }

    a {
        color: var(--primary-color);
        font-weight: bold;
    }

    .m-item {
        overflow-y: auto;
        padding: 0 22px;

        &.m-item-second {
            .title {
                font-size: 13px;
                color: #AEAEAE;
                font-weight: normal;
                margin: 15px 0;
            }
        }
    }

    .version {
        padding: 0 22px;

        .c-divider {
            margin: 0px;
        }

        .ver {
            margin-top: 13px;
        }
    }

    .MuiList-root {
        font-weight: bold;
        white-space: unset !important;

        a {
            display: grid;
            grid-template-rows: auto auto;
            width: 100%;
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: -2px;
                right: 0;
            }
        }
        .c-list-item-arrow {
            &:after{
                z-index: -1;
            }
        }
    }

    .person-info {
        padding-bottom: 10px;

        .c-avatar {
            margin-right: 15px;
            font-weight: normal;
        }

        .blurred {
            font-weight: normal;
        }
    }

    .menu-header {
        padding: 0;
        position: relative;
        height: 81px;
        overflow: hidden;

        .c-btn {
            background-color: transparent;
        }

        .grad {
            position: absolute;
            top: 0;
            width: 100%;
            height: 81px;
            background: var(--no-status);

            .p-first,
            .p-second {
                background-color: var(--no-status);
            }
        }

        .close {
            width: 24px;
            height: 24px;
            position: absolute;
            background-image: url(../../assets/images/close.svg);
            cursor: pointer;
            top: 28px;
            right: 21px;
            margin: 0;
            padding: 0;
            border: 0;
        }

        .p-first,
        .p-second {
            position: absolute;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
            width: 100px;
            height: 200px;
        }

        .p-first {
            transform: rotate(44.5deg);
            top: -70px;
            left: -33px;
        }

        .p-second {
            transform: rotate(27.5deg);
            top: -70px;
            left: -65px;
        }
    }
}