#admin-app {
    .table.ant-table-wrapper .ant-pagination {
        font-size: 14px;
        font-weight: bold;
        padding: 0;
        margin: 0;
        color: var(--table-primary-color);
        background: var(--app-background);
        display: flex;
        align-items: center;
        li {
            margin-bottom: 0;
        }
        .ant-pagination-total-text, .ant-pagination-item-ellipsis {
            line-height: 30px;
            color: var(--primary-color);
        }
        .ant-pagination-item-link {
            border-color: var(--primary-color);
            border-width: 2px;
            border-radius: 50%;
            min-width: 30px;
            height: 30px;
            .anticon {
                vertical-align: 0;
                font-size: 9px;
                svg {
                    fill: var(--primary-color);
                    filter: drop-shadow(0px 0.5px 0px var(--primary-color)) drop-shadow(0px -0.5px 0px var(--primary-color))  drop-shadow(0.5px 0px 0px var(--primary-color)) drop-shadow(-0.5px 0px 0px var(--primary-color));
                }   
            }
        }
        .ant-pagination-disabled {
            .ant-pagination-item-link {
                border-color: var(--muted-color);
                .anticon {
                    svg {
                        padding-left: 0;
                        fill: var(--muted-color);
                        filter: drop-shadow(0px 0.5px 0px var(--muted-color)) drop-shadow(0px -0.5px 0px var(--muted-color))  drop-shadow(0.5px 0px 0px var(--muted-color)) drop-shadow(-0.5px 0px 0px var(--muted-color));
                    }   
                }
            }
        }
        .ant-pagination-next,
        .ant-pagination-prev {
            margin-left: 5px;
            min-width: 30px;
            height: 30px;
            line-height: 28px;
        }
        .ant-pagination-item {
            color: var(--table-primary-color);
            margin: 0 0 0 5px;
            min-width: 30px;
            height: 30px;
            line-height: 28px;
            a {
                color: var(--table-primary-color);
                text-decoration: none;
            }
            &.ant-pagination-item-active {
                background: var(--table-primary-color);
                border-color: var(--table-primary-color);
                border-radius: 50%;
                > a {
                    color: white;
                    font-weight: bold;
                }
            }
        }
        .ant-pagination-options {
            margin-left: 15px;
            .ant-select {
                outline: none;
                > .ant-select-selector {
                    border-radius: var(--btn-border-radius);
                }
                &:hover > .ant-select-selector {
                    border-right-width: 2px !important;
                }
            }
            .ant-select-selector {
                border: 2px solid var(--primary-color);
                height: 30px;
                color: var(--primary-color);
                font-size: 14px;
                background: transparent;
                outline: none;
                &:hover {
                    border-right-width: 2px !important;
                }
                .ant-select-selection-item {
                    line-height: 24px;
                }
            }
            .ant-select-arrow {
                color: var(--primary-color);
                font-size: 9px;
                font-weight: bold;
                padding-top: 1px;
            }
        }
    }
}

