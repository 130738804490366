.autoscreening-questions {
    .questions {
        min-height: 465px;
        h1 {
            font-weight: bold;
            font-size: 17px;
            line-height: 23px;
            margin-bottom: 25px;
        }
        .space{
            margin: 10px;
        }
        .error {
            font-size: 15px;
            line-height: 22px;
        }
        ul {
            margin-bottom: 0;
            margin-top: 15px;
            li {
                margin: 0 0 0 15px;
                font-size: 14px;
                font-weight: normal;
            }
        } 
        h2 { font-size: 18px !important; }
        > p {
            line-height: 150.57%;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    .c-radio-group,
    .c-checkbox-group {
        margin-top: 35px;
    }

    .radio-text-area {
        .am-textarea-item {
            padding-right: 0 !important;
        }
    }
}