#admin-app {
    .table .hierarchy-item {
        > span:last-of-type {
            font-weight: normal;
        }
    }
    .hierarchy-item {
        > a, 
        > span {
            text-decoration: none!important;
            &:nth-of-type(1) {
                .anticon-right {
                    display: none;
                }
            }
            .anticon-right {
                opacity: .4;
                font-size: 10px;
                margin: 0 5px;
            }
            &:last-of-type {
                font-weight: bold;
            }
        }
    }
}