#admin-app {
    .ant-btn-icon-only:not(.ant-btn-circle):not(.ant-input-search-button) {
        border-radius: var(--btn-border-radius);
    }
    .ant-btn {
        &.ant-btn-ghost {
            color: var(--primary-color);
        }
        &.ant-btn-link {
            font-size: 13px;
            font-weight: bold;
            color: var(--primary-color);
            height: auto;
            &[disabled] * {
                color: var(--disabled-color);
            }
        }
        &[disabled] {
            opacity: 0.3!important;
        }
        
    }
    .ant-btn:not(.ant-btn-circle):not(.ant-btn-link):not(.ant-btn-icon-only):not(.ant-input-search-button){
        height: var(--btn-height);
        padding: 0 15px;
        font-weight: bold;
        font-size: 13px;
        border: 2px solid var(--btn-color);
        background: transparent;
        color: var(--btn-color);
        min-width: 117px;
        border-radius: var(--btn-border-radius);
        &.ant-btn-ghost{
            color: var(--primary-color);
            border-color: var(--primary-color);
        }
        &.ant-btn-primary {
            border: 2px solid var(--btn-color);
            background: var(--btn-color);
            color: white;
            box-shadow: none;
            &:active{
                background-color: var(--primary-color);
                border: none;
                color: white;
            }
        }
        &.ant-btn-sm {
            height: var(--btn-sm-height);
            font-size: 12px;
            line-height: 18px;
            min-width: 90px;
        }
        &.ant-btn-danger  {
            color: var(--error-color);
            border-color: var(--error-color);
        }
    }
    .btn-add {
        padding: 0;
        display: grid;
        grid-template-columns: auto auto;
        svg {
            display: none; 
        }
        span {
            font-size: 12px;
        }
        &:disabled {
            color: var(--disabled-color);
            .anticon-plus-circle {
                border-color: var(--disabled-color);
            }
        }
        .anticon-plus-circle {
            width: 22px;
            height: 22px;
            border: 2px solid var(--primary-color);
            border-radius: 50%;
            position: relative;
            &::before, &::after {
                display: block;
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border: 2px solid var(--primary-color);
                border-top: none;
                border-left: none;
                top: 4px;
                left: 4px;
            }
            &::after {
                transform: rotate(180deg);
                top: 8px;
                left: 8px;
            }
        }
    }
    .btn-action {
        background-color: var(--info-color) !important;
        border-color: var(--info-color) !important;
    }
}