.c-modal {
    &.medical-consent-modal {
        .MuiPaper-root {
            max-width: 701px;
        }
        .MuiDialogContent-root {
            padding: 20px 12px
        }
        h1 {
            font-size: 24px;
            line-height: 26px;
        }
        p {
            font-size: 15px;
            line-height: 20px;
            color: var(--primary-color);
        }
    }
}