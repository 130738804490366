#admin-app {
    .ant-dropdown-menu {
        li {
            font-weight: bold;
            font-size: 13px;
            &:not(.ant-dropdown-menu-item-divider) {
                padding: 8px 30px 8px 20px;
            }
            &.ant-dropdown-menu-item-disabled {
                font-weight: normal;
            }
            >.anticon {
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
}