#user-app {
    .c-radio {
        min-height: 67px;
        border: 2px solid var(--input-background);
        background-color: var(--input-background);
        border-radius: var(--radio-border-radius);
        margin: 0 0 18px 0;
        color: var(--primary-color);
        > span.MuiTypography-root {
            padding: 15px 10px 15px 0;
            font-weight: bold;
            font-family: var(--font-family);
            font-size: 14px;
        }
        &.c-radio-checked {
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
            border-color: var(--primary-color);
        }
    }
}