
#admin-app .ant-alert {
    border-radius: var(--input-border-radius);
    padding: 11px;
    .ant-alert-message {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
    }   
    .ant-alert-description {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
    } 
    &.ant-alert-success {
        background: #d2f4f0;
        border-color: #d2f4f0;
        color: var(--primary-color);
        span, .ant-alert-description, .ant-alert-message {
            color: var(--primary-color);
        }
    }
    &.ant-alert-error {
        background: linear-gradient(88.23deg, #FF365F 6.21%, #FF0088 111.55%);
        border: 1px solid rgba(74, 148, 255, 0.2);
        span, .ant-alert-description, .ant-alert-message {
            color: var(--primary-color);
        }
    }
    &.ant-alert-info {
        background: var(--info-banner-color);
        border-color: var(--info-banner-color);
        margin: 25px 0;
        .ant-alert-message{
            font-size: 16px;
            line-height: 20px;
        }
        .ant-alert-icon, .ant-alert-close-icon {
            width: 17px;
            height: 17px;
            border: 2px solid white;
            border-radius: 50%;
            margin-right: 11px;
            position: relative;
            svg {
                display: none; 
            }
        }
        span.anticon-info-circle {
            &::after {
                content: 'i';
                color: white;
                position: absolute;
                font-size: 13px;
                font-weight: bold;
                top: 6px;
                left: 4.5px;
                font-family: serif;
            }
        }
        .ant-alert-close-icon {
            margin: 0;
            &::before, &::after {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border: 2px solid white;
                border-top: none;
                border-left: none;
                transform: rotate(45deg);
                top: 2px;
                left: 4px;
            }
            &::after {
                transform: rotate(-135deg);
                top: 6px;
            }
        }
        span, .ant-alert-description, .ant-alert-message {
            color: white;
        }
    }
}


/*
.ant-alert-message{
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
}
.ant-alert-description{
    font-style: normal;
    font-size: 12px;
}
*/