
.notification-tooltip {
    margin-left: 25px;
    width: 300px;
    max-width: 300px;
    .ant-tooltip-arrow {
        right: 3px;
    }
    .ant-tooltip-inner {
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.4);
        font-size: 14px;
        padding: 15px 25px;
        border-radius: 7px;
        h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 15px 0;
        }
        p {
            color: #1d1d1d;
            font-size: 13px;
        }
        .c-btn {
            display: block;
            margin: 25px auto 10px auto;
            outline: 0;
            border-radius: 100px !important;
            text-align: center;
        }
    }
}
