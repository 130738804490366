@import './Pagination.scss';

.table {
    --table-highlight-color:  var(--highlight-color);
    --table-primary-color:  var(--primary-color);
    --table-background: var(--app-background);
}

.ant-table-sticky-scroll {
    background-color: var(--app-scrollbar-bgr);
    opacity: unset;
    .ant-table-sticky-scroll-bar {
        background-color: var(--app-scrollbar-thumb);
    }
}

#admin-app .table.ant-table-wrapper {
    font-size: 12px!important;
    >.ant-spin-nested-loading {
        >.ant-spin-blur {
            overflow: visible;
        }
    }
    .ant-table {
        font-size: 12px!important;
        background-color: transparent;
        color: var(--table-primary-color);
        border-radius: 0;
        &.ant-table-empty {
            .ant-table-body {
                overflow: auto auto!important;
                .ant-empty-normal {
                    margin: 100px auto;
                }
            }
        }
        p {
            font-size: 12px;
        }
        > .ant-table-container {
            border-radius: 0;
            height: calc(100vh - 224px);
            display: grid;
            grid-template-rows: auto 1fr;
            &:after,
            &:before {
                display: none;
            }
            thead, .ant-table-header  {
                tr {
                    > th {
                        background-color: var(--table-background);
                        color: var(--table-primary-color);
                        font-weight: bold;
                        border-radius: 0;
                        padding: 8px 14px; 
                        &.ant-table-cell-fix-left:first-of-type {
                            padding: 0;
                            .anticon-setting svg {
                                width: 17px;
                                height: 17px;
                            }
                        }
                        .ant-table-column-sorters {
                            padding: 0;
                        }
                        .ant-table-column-sorter {
                            .anticon {
                                color: var(--table-primary-color);
                            }
                            .anticon.active {
                                color: var(--table-highlight-color);
                            }
                        }
                        .anticon-filter{
                            color: var(--table-primary-color);
                            &.filtered {
                                color: var(--table-highlight-color);
                            }
                        }
                        .anticon-question-circle {
                            margin-left: 0;
                            margin-right: 6px;
                        }
                    }
                }
            }
            tbody,.ant-table-body {
                scrollbar-width: thin;
                height: 100%;
                max-height: 100% !important;
                border-bottom: 1px var(--border-color) solid;
                tr {
                    &.loading {
                        > td {
                            color:var(--muted-color);
                            background: var(--selected-color);;
                        }
                    }
                    > td {
                        padding: 11px 5px 11px 15px;
                        background-color: var(--table-background);
                        vertical-align: top;
                        &.right {
                            text-align: right;
                            float: none;
                        }
                        &.ant-table-selection-column {
                            width: 40px;
                            min-width: 40px;
                        }
                        &.ant-table-cell-fix-left:first-of-type {
                            padding: 0px;
                            padding-top: 5px;
                        } 
                        // &.ant-table-cell-fix-right-first {
                        //     padding: 0 0 0 15px;
                        // }
                        .ant-btn-icon-only {
                            >.anticon-ellipsis {
                                font-weight: 600;
                                font-size: 16px;
                                color: var(--primary-color);
                                svg path {
                                    stroke: currentColor;
                                    stroke-width: 40px;
                                }
                            }
                            &[disabled]> .anticon {
                                color: var(--muted-color);
                            }
                        }
                    }
                }
            }
        }
    }
}

#admin-app .ant-modal {
    .ant-table-wrapper {
        .ant-table {
            >.ant-table-container {
                height: calc(100vh - 420px)!important;
            }
        }
    }
}
#admin-app {
    .table {
        .ant-table-tbody {
            a {
                text-decoration: underline;
                text-underline-offset: 3px;
                text-decoration-color: var(--muted-color);
            }
        }
        &.clickable {
            .ant-table-tbody > tr {
                cursor: pointer;
            }
        }
        .cell-html {
            background-color: white!important;
        }
        .list-item-module.module {
            float: left;
            margin: 3px 5px 5px 0;
            > div:first-child {
                width: 20px;
                height: 20px;
                &::after {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}