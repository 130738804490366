#admin-app {
    .tree-multiselect > .ant-select-selector {
        max-height: 120px;
        overflow: auto;
    }
    .tree-multiselect-dropdown {
        .ant-tree {
            border-top: 1px var(--border-color) solid;
        }
        border-radius: 0;
        padding: 12px;
        .dropdown-footer {
            border-top: 1px var(--border-color) solid;
            display: grid;
            grid-template-columns: 1fr auto auto;
            align-items: center;
            column-gap: 12px;
            padding-top: 12px;
        }
    }
}