
.PhoneInput{
	font-family: var(--font-family);
	font-weight: bold;
	width: 100%;
	border-radius: var(--input-border-radius);
	background-color:  var(--input-background);
	border: var(--input-border)!important;
	display: grid;
	grid-template-columns: 63px auto;
	column-gap: 10px;
	
	&.PhoneInput-disabled .PhoneInputCountryIcon{
		opacity: 0.4;
	}
	>.PhoneInputCountry {
		.PhoneInputCountryIcon img{
			width: 24px;
			height: 24px;
			border-radius: 50%;
		}
	}
	.PhoneInputInput {
		background: transparent;
	}
	.MuiSelect-select {
		padding: 0!important;
		&:focus {
			background-color: transparent;
		}
	}
	.MuiSelect-icon  {
		right: 0!important;
		margin-top: 2px;
		
	}
	> input{
		font-family: var(--font-family);
		border: none!important;
		font-weight: bold;
		width: 100%;
		height: var(--input-height);
		border-radius: 25px;
		&:focus{
			outline: none;
		}
		&:disabled{
			color: #00000042;
		}
	}
}

:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}
