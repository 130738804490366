@modalPadding: 20px;
.c-modal {
    img.icon-modal {
        display: block;
        margin: 25px auto 20px auto;
        width: 75px;
    }
    h1 {
        text-align: center;
    }
    .c-modal-footer {
        border-top: 1px var(--border-color) solid;
        display: block;
        padding: 15px;
        button.primary + button.link {
            margin: 15px 0;
        }
    }
    .c-modal-title {
        font-size: 18px;
        color: var(--primary-color);
        font-weight: 700;
    }
}

.am-modal-mask {
    z-index: 9998!important;
}
.am-modal-wrap {
    z-index: 9999!important;
}