
.location-card{
    --avatar-block-height: 25px;
    display: table;
    &.block {
        height: var(--avatar-block-height);
        > span {
            line-height: var(--avatar-block-height);
            float: left;
            &.ant-avatar {
                margin-top: 2px;
            }
            &:nth-of-type(2){
                display: block;
                margin: 0px;
                text-align: left;
                line-height: var(--avatar-block-height);
                div:nth-of-type(1) {
                    line-height: 15px;
                    min-height: 8px;
                    font-size: 12px;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 160px;
                }
                div:nth-of-type(2) {
                    font-size: 11px;
                    line-height: 13px;
                    color: var(--muted-color);
                }
            }
        }           
    }
    > span {
        &:nth-child(2) {
            display: table-cell;
            padding-left: 12px;
            line-height: 24px;
        }
    }
}
