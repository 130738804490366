#admin-app {
    .attachments {
        margin: 10px 0;
        padding:  0;
        border-top: 1px solid #d6d6d6;
        > div{
            margin: 12px 0;
            display: grid;
            grid-template-columns: 40px 1fr auto;
            > img{
                width: 25px;
                height: 25px;
            }
            > span{
                display: inline-block;
                vertical-align: middle;
                font-size: 13px;
                line-height: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            >.ant-btn{
                padding: 0;
                width: 85px;
                border: 2px solid #4A94FF;
                box-sizing: border-box;
                >span{
                    color: #4A94FF;
                    font-size: 13px;
                    line-height: 13px;
                    font-weight: 700;
                }
            }
        }
    }
}