
.questions-banner-modal{
    h4 {
        font-size: 14px !important;
        margin-bottom: 20px;
    }
    span{
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;
        letter-spacing: -0.02em;
    }
}