

#user-app {
    .c-help {
        padding: 0;
        svg {
            color: var(--highlight-color) !important;
        }
    }
    .c-help-modal {
        .MuiPaper-root {
            background-color: black;
            border-radius: 7px;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
            color: white;
            .MuiSvgIcon-root, .c-btn {
                color: var(--highlight-color);
            }
            .MuiDialogActions-root {
                border-top: none;
            }
            .c-modal-close-button {
                display: none;
            }
            .c-modal-footer {
                padding-left: 26px;
            }
        }
    }
}