#admin-app {
    .multiselect {
        &.ant-select-disabled {
            .ant-select-selector {
                .ant-select-selection-item {
                    background: #b9b9b9;
                    .ant-select-selection-item-remove {
                        display: none;
                    }
                }
            }
        }
        .ant-select-selector{
            padding: 0 9px;
            background: transparent !important;
            border-color: var(--input-border-color);
            .ant-select-selection-overflow {
                padding: 4px 19px 6px 0;
            }
            .ant-select-selection-item{
                background: var(--primary-color);
                color: white;
                height: 22px;
                line-height: 20px;
                font-size: 12px;
                font-weight: bold;
                padding: 0 23px 0 7px;
                margin: 3px 3px 0 0;
                .ant-select-selection-item-content{
                    font-size: 12px;
                    margin: 0;
                }
            }
            .ant-select-selection-search {
                margin: 0;
                font-size: 12px;
                > input {
                    font-weight: bold;
                }
            }
            .ant-select-selection-item-remove{
                position: absolute;
                background: white;
                border-radius: 100%;
                height: 10px;
                width: 10px;
                margin: 0;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                display: flex;
                .anticon.anticon-close{
                    color: var(--primary-color);
                    margin: auto;
                    font-size: 6px;
                    margin-top: 2px;
                }
            }
            .ant-select-selection-placeholder {
                font-size: 12px;
                left: 10px;
            }
        }
    }
}