#admin-app .confirm-dialog {
    .ant-btn:not(.ant-btn-primary) {
        border: 2px var(--primary-color) solid;
    }
    .ant-modal-confirm-body {
        display: grid;
        grid-template-columns: 40px 1fr;
        column-gap: 15px;
        > .ant-modal-confirm-title {
            font-size: 18px;
            font-weight: bold;
            color: var(--primary-color);
        }
        > .ant-modal-confirm-content {
            max-width: unset;
            margin-bottom: 25px;
        }
        > img {
            width: 40px;
            height: 40px;
            grid-row-start: 1;
            grid-row-end: 3;
            align-self: start;
        }
        p {
            text-align: left;
        }
    }
}