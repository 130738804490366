#user-app {
    .c-select {
        width: 100%;
        border-radius: var(--input-border-radius);
        background-color: var(--input-background);
        border: var(--input-border)!important;
        height: var(--input-height);
        font-family: var(--font-family);
        font-size: var(--input-font-size);
        font-weight: bold;
        &:before {
            display: none;
            padding: 16px 24px;
        }
        >.MuiSelect-select{
            color: var(--primary-color);
            padding-right: 32px;
            &:focus{
                background-color: transparent;
            }
        }
        
        .MuiSelect-icon {
            right: 3px;
        }
    }
    .c-select-dropdown {
        .MuiPaper-root {
            margin-top: 9px;
            padding: 10px 0;
        }
        li{
            min-height: 50px;
            font-family: var(--font-family);
            color: var(--primary-color);
            font-weight: bold;
        }
        .Mui-selected {
            background-color: var(--input-background);
            font-weight: bold;
        }
    }
}