.lang-modal-wrap{
    z-index: 9999!important;
    .am-picker-col-item  {
        text-align: left;
        padding-left: calc(50% - 60px);
        img {
            width: 25px;
            height: 25px;
            margin-right: 15px;
        }
        &.am-picker-col-item-selected {
            span {
                font-weight: bold;
            }
        }
    }
}

.popup-helper {
    max-width: 75%;
    margin: 3px auto;
}
