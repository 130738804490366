#user-app {
    .c-menu {
        .MuiPaper-root {
            min-width: 250px;
            overflow: visible;
            filter: drop-shadow(1px 2px 8px rgba(0, 0, 0, 0.1));
            margin-top: 7px;
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                right: 14px;
                width: 10px;
                height: 10px;
                background-color: white;
                transform: translateY(-50%) rotate(45deg);
                z-index: 0;
            }
        }
        ul {
            padding: 19px 18px 12px 18px;
            > li {
                min-height: 58px;
                font-family: var(--font-family);
                color: var(--primary-color);
                font-weight: bold;
                font-size: 15px;
                line-height: 19px;
                white-space: normal;
                svg {
                    font-size: 22px;
                }
            }
        }
        .btn-cancel {
            border-top: 1px var(--border-color) solid;
            padding-top: 12px;
            margin-top: 10px;
            color: var(--highlight-color);
        }
    }
}