
@import "fonts";
@import '~react-quill/dist/quill.bubble.css';

html, body {
    margin: 0; 
    padding: 0;
    height: 100%;
    color: var(--primary-color);
    font-family: var(--font-family);
}
body {
    background-color: var(--app-background)!important;
    color: var(--primary-color);
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0,0,0,.85);
    font-weight: 500;
}
*, :after, :before {
    box-sizing: border-box;
}
span, p, ul, li, label {
    font-family: var(--font-family); 
}
p{
    font-size: 14px;
}
a {
    text-decoration-line: underline;  
}


blockquote {
    border-left: 4px solid #ece6d7;
    margin-bottom: 15px;
    margin-top: 5px;
    padding-left: 16px;
}
label {
    min-height: 30px;
    height: auto;
}