#admin-app {
    .PhoneInput {
        height: var(--input-height);
        border-radius: var(--input-border-radius);
        border: 1px solid var(--input-border-color)!important;
        background: var(--app-background);
        grid-template-columns: 50px auto;
        column-gap: 0;
        input {
            background: var(--app-background);
            height: 100%;
            font-size: 12px;
            font-weight: bold;
            line-height: 28px;
        }
        img {
            margin-top: 7px;
        }
        .PhoneInputCountry {
            padding: 0 0px 0 5px;
            .PhoneInputCountryIcon img {
                border-radius: 0;
                height: 14px;
            }
        }
    }
    .ant-form-item-has-error .PhoneInput {
        border-color: var(--error-color);
    }
        
    .phone-input-dropdown {
        ul {
            min-width: 250px;
            > li {
                height: 50px;
            }
            .MuiListItemIcon-root img {
                height: 14px;
                border-radius: 0;
            }
        }
    }
}